// import simpleParallax from 'simple-parallax-js';

// Form
import './form/form'


// Hamburger
import './hamburger/hamburger'
import './overlay/overlay'
import './welcome/welcome'
import './modal/modal'
// import './hit/hit'
// import './card/card'
// import './article/article'
// import './header/header'
// import './feedback/feedback'
// import './useful/useful'
// import './footer/footer'
// import './modal/modal'
// import './menu/menu'
// import './accordion/accordion'
// import './filter/filter'
// import './detail/detail'
// import './accessories/accessories'
// import './also/also'
// import './header/header'
